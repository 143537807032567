/*
 * @Descripttion:库课在线相关  用户协议 - 隐私协议路由
 * @version:
 * @Author: wangxiaoheng
 * @Date: 2022-04-06 13:17:25
 * @LastEditors: wangxiaoheng
 * @LastEditTime: 2022-04-06 13:23:41
 */

const lineRouter = [
  {
    path: "/online/agreement/afterSale",
    name: "ag_afterSale",
    meta: { title: "库课在线用户协议", noCache: true },
    component: () => import("../../views/online/agreement/afterSale.vue"),
  },
  {
    path: "/online/agreement/privacy",
    name: "ag_privacy",
    meta: { title: "库课在线隐私协议", noCache: true },
    component: () => import("../../views/online/agreement/privacy.vue"),
  },
  {
    path: "/online/download",
    name: "online_download",
    meta: { title: "库课在线下载", noCache: true },
    component: () => import("../../views/online/download/index.vue"),
  },
];

export default lineRouter;
