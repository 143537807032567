<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="isKeeplive"></router-view>
    </keep-alive>
    <router-view v-if="!isKeeplive"></router-view>
  </div>
</template>
<script>
export default {
  computed: {
    isKeeplive: function () {
      return !this.$route.meta.noCache;
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
