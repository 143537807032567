import Vue from "vue";
import VueRouter from "vue-router";
import lineRouter from "./modules/onLine.js"; // 库课在线先关协议
import bookRouter from "./modules/kkBook.js"; // 库课图书先关协议
import openWX from "./modules/openwx"; // 微信公众号

Vue.use(VueRouter);

const routes = [
  ...openWX,
  ...lineRouter,
  ...bookRouter,
  // 二维码路由不能添加权限不能删除 （图书二维码）如以后配置权限需要加入白名单。任何都可以访问
  {
    path: "/qrcode",
    name: "qrcode",
    meta: {
      title: "图书二维码",
      noCache: true,
    },
    component: () => import("../views/qrcode/index.vue"),
  },
  {
    path: "/infocode",
    name: "infocode",
    meta: {
      title: "库课图书-领取资料",
      noCache: true,
    },
    component: () => import("../views/infoCode/index.vue"),
  },
  // 协议下隐私政策
  {
    path: "/agreement/privacy",
    name: "ag_privacy",
    meta: { title: "隐私政策", noCache: true },
    component: () => import("../views/agreement/privacy.vue"),
  },
  // 协议下售后隐私
  {
    path: "/agreement/afterSale",
    name: "ag_afterSale",
    meta: { title: "售后协议", noCache: true },
    component: () => import("../views/agreement/afterSale.vue"),
  },
  {
    path: "/",
    name: "privacy",
    meta: { title: "隐私政策", noCache: true },
    component: () => import("../views/privacy.vue"),
  },
  // {
  //   path: "/public",
  //   name: "public",
  //   meta: { title: "库课技术发布", noCache: true },
  //   component: () => import("../views/public/index.vue"),
  // },
  {
    path: "/download",
    name: "download",
    meta: { title: "库课教师-助你一站成师", noCache: true },
    component: () => import("../views/download/index.vue"),
  },
  {
    path: "/demo",
    name: "demo",
    meta: { title: "test", noCache: true },
    component: () => import("../views/demo.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    meta: { title: "用户协议", noCache: true },
    component: () => import("../views/agreement.vue"),
  },
  {
    path: "/afterSale",
    name: "afterSale",
    meta: { title: "售后政策", noCache: true },
    component: () => import("../views/afterSale.vue"),
  },
  {
    path: "/dropOut",
    name: "dropOut",
    meta: { title: "退课须知", noCache: true },
    component: () => import("../views/dropOut.vue"),
  },
  {
    path: "/refund",
    name: "refund",
    meta: { title: "退款说明", noCache: true },
    component: () => import("../views/refund.vue"),
  },
  {
    path: "/helpCenter",
    name: "helpCenter",
    meta: { title: "帮助中心", noCache: true },
    component: () => import("../views/helpCenter.vue"),
  },
  {
    path: "/payProblem",
    name: "payProblem",
    meta: { title: "支付问题", noCache: true },
    component: () => import("../views/payProblem.vue"),
  },
  {
    path: "/commonProblem",
    name: "commonProblem",
    meta: { title: "常见问题", noCache: true },
    component: () => import("../views/commonProblem.vue"),
  },
  {
    path: "/management",
    name: "management",
    meta: { title: "经营许可", noCache: true },
    component: () => import("../views/management.vue"),
  },
  {
    path: "/productDetails",
    name: "productDetails",
    meta: { title: "商品详情", noCache: true },
    component: () => import("../views/productDetails.vue"),
  },
  {
    path: "/oneCodeQuestion",
    name: "oneCodeQuestion",
    meta: { title: "试题详情", noCache: true },
    component: () => import("../views/kkExam/index.vue"),
  },
];

const router = new VueRouter({
  // mode : 'history',
  routes,
});

export default router;
