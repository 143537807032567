/*
 * @Descripttion: 库课图书相关  用户协议 - 隐私协议路由
 * @version:
 * @Author:
 * @Date: 2022-04-06 13:17:25
 * @LastEditors:
 * @LastEditTime: 2022-04-06 13:23:41
 */

const bookRouter = [
  {
    path: "/kkbook/agreement/term",
    name: "kkBook_ag_term",
    meta: { title: "用户协议", noCache: true },
    component: () => import("../../views/kkBook/agreement/term.vue"),
  },
  {
    path: "/kkbook/agreement/privacy",
    name: "kkBook_ag_privacy",
    meta: { title: "隐私政策", noCache: true },
    component: () => import("../../views/kkBook/agreement/privacy.vue"),
  },
];

export default bookRouter;
