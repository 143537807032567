const openWX = [
  {
    path: "/openwx",
    name: "openWX",
    meta: { title: "精选好课", noCache: true },
    component: () => import("../../views/openWX/openWX.vue"),
  },
  {
    path: "/openapp",
    name: "openApp",
    meta: { title: "下载", noCache: true },
    component: () => import("../../views/openApp/openApp.vue"),
  },
  // {
  //   path: "/kukePrjEntrance",
  //   name: "kukePrjEntrance",
  //   meta: { title: "库课项目入口", noCache: true },
  //   component: () => import("../../views/kukePrjEntrance"),
  // },
  {
    path: "/receiveMaterial",
    name: "receiveMaterial",
    meta: { title: "库课图书学习资料免费送", noCache: false },
    component: () => import("../../views/receiveMaterial/receiveMaterial.vue"),
  },
  {
    path: "/receiveSuccess",
    name: "receiveSuccess",
    meta: { title: "库课图书学习资料免费送", noCache: false },
    component: () => import("../../views/receiveMaterial/success.vue"),
  },
  {
    path: "/bookCorrect",
    name: "bookCorrect",
    meta: { title: "库课图书_勘误汇总", noCache: false },
    component: () => import("../../views/bookCorrect/bookCorrect.vue"),
  },
  {
    path: "/bookCorrect/search",
    name: "search",
    meta: { title: "搜索", noCache: false },
    component: () => import("../../views/bookCorrect/search.vue"),
  },
  {
    path: "/bookCorrect/correctDetail",
    name: "correctDetail",
    meta: { title: "图书勘误详情", noCache: true },
    component: () => import("../../views/bookCorrect/correctDetail.vue"),
  },
];

export default openWX;
