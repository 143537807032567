import "@/styles/index.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Tab, Tabs, Overlay, Popup, Icon } from "vant";
import "lib-flexible/flexible";
// import VConsole from 'vconsole';
// new VConsole();
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Overlay);
Vue.use(Popup);
Vue.use(Icon);
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
